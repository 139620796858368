import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import {
    Tile, Button, Pagination,
    ToolbarItem,
    Toggle,
} from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import { Modal, TextInput, TextArea } from 'carbon-components-react';
import FacetsBrowse from 'components/roboroy/common/FacetsBrowse';
import {
    getBatchDetails,
    setCurrentBatch,
    setActiveBatch,
    addNewBatch,
    editBatch,
    closeJobBatch,
    getJobsByBatch,
    getNpBatchList
} from 'actions/BatchManagementView';
import { getAllFaculties } from 'actions/candidate/CandidateDetails';
import { batch, connect } from 'react-redux';
import moment from 'moment';
import {
    Checkbox,
    DatePicker,
    DatePickerInput,
    InlineNotification
} from 'carbon-components-react';
import "./BatchManagementView.css"
import { NonceProvider } from 'react-select';

class BatchManagementView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      propsArr: [],
      isAddBatchModalOpen: false,
      showDateError: false,
      dateErrorMsg: '',
      showNameError: false,
      nameErrorMsg: '',
      batchNameValue: '',
      startDate: '',
      endPeriod: '',
      modalHeading: '',
      closeJobMessage: '',
      npBatchOptions: []
    }
  }

  componentDidMount() {
    this.handleGetBatchData();
    this.handleGetNpBtachOptions();
  }

  handleGetNpBtachOptions = async () => {
    try {
      let res = await this.props.getNpBatchList();
      if(res && !res.error) {
        const npBatchOptions = res && Array.isArray(res) && res.length > 0 ? res.map((r) => ({
          label: r.batchName,
          value: r.batchId
        })): [];
        this.setState({
          npBatchOptions,
        })
      }
      
    } catch (error) {
      console.error(error);
    }
  }

  onCurrentBatchChange = (id, value, name) => {
    this.setState({ currentBatchValue: value })
  }

  handleSaveCurrentBatch = () => {
    const { currentBatchValue } = this.state;
    const data = {
      batchId: currentBatchValue.value,
      isCurrentBatch: true
    }
    this.props.setCurrentBatch(data).then((res) => { this.handleGetBatchData() })
  }

  handleAddBatchModal = (value) => {
    this.setState({
        isAddBatchModalOpen: value,
        showDateError: false,
        showNameError: false,
        nameErrorMsg: '',
        dateErrorMsg: '',
        npBatchValInvalid: false,
    }, () => {
      if (value === false) {
        this.setState({ batchNameValue: '', npBatchVal: null, propsArr: null })
        this.handleGetBatchData();
      }
    })
  }

  handleAddBatchModalSubmit = () => {
    const { batchNameValue, propsArr, modalHeading, batchIdToEdit, npBatchVal } = this.state;
    let showNameError = false;
    let nameErrorMsg = '';
    let npBatchValInvalid = false;
    if (batchNameValue === '') {
      showNameError = true;
      nameErrorMsg = "Batch name cannot be empty";
    }
    if(!npBatchVal) {
      npBatchValInvalid = true;
    }
    this.setState({
      showNameError,
      nameErrorMsg,
      npBatchValInvalid,
    })
    if(showNameError || npBatchValInvalid)
      return;
    const batchesToAdd = []
    for (let i in propsArr) {
      const prop = propsArr[i];
      if (prop.isSelected === true) {
        if (prop.creatingBatch.startDate === '' || prop.creatingBatch.endDate === '') {
          this.setState({
            showDateError: true,
            dateErrorMsg: "Dates cannot be empty for selected schools"
          })
          return;
        }
        batchesToAdd.push({
          "facultyId": parseInt(prop.facultyId),
          "batchStartDate": moment(prop.creatingBatch.startDate).format("DD/MM/YYYY"),
          "batchEndDate": moment(prop.creatingBatch.endDate).format("DD/MM/YYYY")
        })
      }
    }

      const data = {
          "batchName": batchNameValue,
          "batchDetails": batchesToAdd,
          npBatchName: npBatchVal && npBatchVal.label,
          npBatchId: npBatchVal && npBatchVal.value,
      }
      if (modalHeading === "Edit Batch") {
          data["batchId"] = batchIdToEdit;
          this.props.editBatch(data).then((res) => {
              this.handleAddBatchModal(false);
          })
      } else {
          data["isCurrentBatch"] = false;
          data["isActiveBatch"] = false
          this.props.addNewBatch(data).then((res) => {
              this.handleAddBatchModal(false);
          })
      }
  }

  handleJobBatchModalSubmit = () => {
    let { closeJobMessage, batchNameValue } = this.state;
    let data = {
      reasonForClosure: closeJobMessage,
      batchName: batchNameValue
    }
    this.props.closeJobBatch(data).then((res) => {
      console.log("res###",res)
      if(res && !res.error){
        this.setState({
          isCloseJobBatchModal: false,
        })
        // window.location.reload();
      }
    })
  }

  handleEditBatch = (batchId) => {

    const { batchList } = this.state;
    const requiredBatch = batchList.filter((batch) => (batch.batchId === batchId))[0];
    const { npBatchName, npBatchId } = requiredBatch || {};
    const batchNameValue = requiredBatch.batchName;
    const npBatchVal = { label: npBatchName, value: npBatchId };
    const { batchDetails } = requiredBatch;
    const { facultyData } = this.props;

    const propsArr = [];
    for (let eachFacultyIndx in facultyData) {
      let presentBatch;
      presentBatch = batchDetails.filter((batch) => parseInt(batch.facultyId) === parseInt(facultyData[eachFacultyIndx].facultyId))[0];

      if (presentBatch !== null && presentBatch !== undefined && presentBatch !== {}) {
          propsArr.push({
              label: presentBatch.facultyName,
              isSelected: true,
              creatingBatch: {
                  startDate: presentBatch.batchStartDate,
                  endDate: presentBatch.batchEndDate
              },
              facultyId: presentBatch.facultyId,
          })
      } else {
        propsArr.push({
            label: facultyData[eachFacultyIndx].facultyName,
            isSelected: false,
            creatingBatch: {
                startDate: '',
                endDate: ''
            },
            facultyId: facultyData[eachFacultyIndx].facultyId
        })
      }
    }
    this.setState({ npBatchVal, propsArr, batchNameValue, modalHeading: "Edit Batch", batchIdToEdit: batchId }, () => {
      this.handleAddBatchModal(true)
    })
  }

  handleCloseJobBatchModal = (value) => {
    this.setState({
      isCloseJobBatchModal: value,
    })
  }

  dismissCloseJobModal = () => {
    this.setState({
      isCloseJobBatchModal: false,
    });
  }

  handleCloseJobBatch = (batchId, batchName) => {
    const { batchList } = this.state;
    const requiredBatch = batchList.filter((batch) => (batch.batchId === batchId))[0];
    const batchNameValue = requiredBatch.batchName;
    const { batchDetails } = requiredBatch;
    const { facultyData } = this.props;
    let batchBatchName = batchNameValue;

    this.props.getJobsByBatch(batchBatchName).then((resp) => {
      if(resp && !resp.error){
        this.setState({
          canCloseBatchJob: (resp && resp.canCloseJobs)
        })
      }
    })
    this.setState({
      batchNameValue: batchName
    }, () => {
      this.handleCloseJobBatchModal(true)
    })
  }

  handleGetBatchData() {
    this.props.getBatchDetails().then((res) => {
      const { list: batchList } = res;
      const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
      if(currentBatch!==null && currentBatch!==undefined && batchList!==null && batchList!==undefined){
        this.setState({
          ...this.state,
          currentBatchValue: { label: currentBatch.batchName, value: currentBatch.batchId },
          batchList: batchList
        })
      }else{
        this.setState({
            ...this.state,
            currentBatchValue: {label:"Choose Batch",value:0},
            batchList: batchList
        })
      }
    });

    // const facultyData = {
    //     facultyActivePage: 1,
    //     facultyItemsPerPage: 1000,
    //     facultySearchKey: null,
    // };
    const data = {
      facultyActivePage: 1,
      facultyItemsPerPage: 10,
      schoolId: "1"
    };

    this.props.getAllFaculties(data).then((res) => {
      const faculties = res.faculities;
      let propsArr = [];
      faculties.map((faculty, indx) => {
        propsArr.push({
          label: faculty.facultyName,
          isSelected: false,
          creatingBatch: {
              startDate: '',
              endDate: ''
          },
          facultyId: faculty.facultyId,
        })
      })
        this.setState({ propsArr });
    })
  }

  handleBatchNameChange = (e) => {
    this.setState({ batchNameValue: e.target.value, showNameError: false, nameErrorMsg: '' })
  }

  handleSchoolSelect = (indx) => {
    const { propsArr } = this.state;
    const isSelected = propsArr[indx].isSelected;
    propsArr[indx].isSelected = !isSelected;
    this.setState({ propsArr })
  }

  onBatchDateChange = (e, indx, label) => {
    const date = e[0];
    const { propsArr } = this.state;
    let startPeriod = propsArr[indx].creatingBatch.startDate;
    let endPeriod = propsArr[indx].creatingBatch.endDate;
    if (label === "start") {
        startPeriod = date;
    }
    if (label === "end") {
        endPeriod = date
    }
    if (startPeriod != '' && endPeriod != '' &&
        moment(startPeriod).format('YYYY-MM-DD') >=
        moment(endPeriod).format('YYYY-MM-DD')
    ) {
        this.setState({
            showDateError: true,
            dateErrorMsg: "Enter valid dates. Start date should be before end date."
        });
    } else {
        propsArr[indx].creatingBatch.startDate = startPeriod;
        propsArr[indx].creatingBatch.endDate = endPeriod;
        this.setState({ propsArr, showDateError: false, dateErrorMsg: '' });
    }
  };

  handleClearDate = (indx, label) => {
    const { propsArr } = this.state;
    propsArr[indx].creatingBatch.startDate = '';
    propsArr[indx].creatingBatch.endDate = '';
    this.setState({
        propsArr
    });
  }

  handleToggleActiveBatch = (isActiveBatch, batchId) => {
    const data = {
      isActiveBatch: isActiveBatch,
      batchId: batchId
    }
    this.props.setActiveBatch(data).then(() => { this.handleGetBatchData() })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    })
  }

  render() {
    const { propsArr,
      currentBatchValue,
      isAddBatchModalOpen,
      batchNameValue,
      batchList,
      nameErrorMsg,
      dateErrorMsg,
      modalHeading,
      isCloseJobBatchModal,
      canCloseBatchJob,
      showNameError,
      npBatchOptions,
      npBatchVal,
      npBatchValInvalid,
    } = this.state;

    const { batchDetails, t, user } = this.props;
    const { roleId, isSuperAdmin } = user;
    const columnWithFilter = [
      {
          title: `${t('batch')} ${t('name')}`,
          className: 'text-left',
          name: 'batch',
          propName: 'batch',
      },
      {
          title: `${t('Active')} ${t('batch')}`,
          className: 'text-left',
          name: 'activeBatch',
          propName: 'activeBatch',
      },
      {
          title: `${t('batch')} ${t('details')}`,
          className: 'text-left',
          name: 'batchDetails',
          propName: 'batchDetails',
      },
    ];
    if (roleId === 1 && isSuperAdmin === true) {
      columnWithFilter.push({
          title: 'Action',
          className: 'text-center',
          name: 'editOption',
          propName: 'editOption',
      },
      {
        title: "Batch Closure",
        className: 'text-center',
        name: 'batchClosure',
        propName: 'batchClosure',
      })
    }
    const otherActionButtons = [];
    if (roleId === 1 && isSuperAdmin === true) {
      otherActionButtons.push(
        <Button kind="primary" onClick={() => {
          this.setState({ modalHeading: "Add Batch" }, () => {
            this.handleAddBatchModal(true);
          })
        }}>
          Add Batch
        </Button>)
    }
    const disableClass = (parseInt(roleId) !== 1 && isSuperAdmin !== true) ? 'xpa-disable' : '';

    return (
      <Fragment>
        <div className="h6 mb-2">
            {t('Batch Manager')}
        </div>
        <div className="d-flex align-items-center">
            <div>
                <DropDownBox
                    disabled={roleId !== 1 && isSuperAdmin !== true}
                    title={t('Current Batch')}
                    mainClassName="bx--form-item"
                    titleClass="bx--select"
                    labelClass="bx--label"
                    custom
                    options={(batchList && Array.isArray(batchList)
                        && batchList.length > 0
                        ? (batchList.map((one, indx) => ({
                            label: one.batchName,
                            value: one.batchId,
                        }))) : [])
                    }
                    name={t('currentBatch')}
                    onChange={(id, value, name) => this.onCurrentBatchChange(id, value, name)}
                    // onChange={(e)=>this.onCurrentBatchChange(e)}
                    selectedValue={currentBatchValue}
                />
            </div>
            {roleId === 1 && isSuperAdmin === true &&
                <div className="mt-2 ml-3">
                    <Button style={{
                        minHeight: "2.4rem",
                    }} kind="primary" size="small" onClick={() => this.handleSaveCurrentBatch()}>
                        Save
                    </Button>
                </div>
            }
        </div>

        <GenericDataTable
          className=""
          otherActions={otherActionButtons}
          headers={
            columnWithFilter.map((data, index) => (
              {
                key: `header_${index}`,
                header: data.title,
                className: data.className,
                colpropName: data.propName,
              }
           ))}
            rows={
                (batchList && Array.isArray(batchList)
                    && batchList.length > 0)
                    ? batchList.map((res) => {
                        const {
                            batchName,
                            batchId,
                            isCurrentBatch,
                            isActiveBatch,
                            batchDetails,
                            npBatchName
                        } = res || {};
                        return ({
                            isExpanded: true,
                            id: `row_${batchId}`,
                            header_0: (
                                <div 
                                  style={{minWidth: '5rem'}}
                                  className="align-items-center">
                                    <div>
                                        {batchName}
                                    </div>
                                    {
                                        isCurrentBatch &&
                                        <div style={{ margin: 0, padding: '4px 4px 3px 4px', borderRadius: 2 }} className="xpa-bx--tag--s bx--tag--green">Current Batch</div>
                                    }
                                    {
                                      npBatchName &&
                                      <div style={{ margin: 0, padding: '4px 4px 3px 4px', borderRadius: 2 }} className="mt-1 xpa-bx--tag--s bx--tag--blue"><span className='font-weight-bold mr-1'>{t('ims_batch')}:</span>{' '}{npBatchName}</div>
                                    }
                                </div>
                            ),
                            header_1: (
                                <div className={`d-flex align-items-center ${disableClass}`}>
                                    <Toggle
                                        className="mt-2 mt-lg-0 mt-md-0"
                                        id={`toggle-${batchId}`}
                                        onToggle={() => this.handleToggleActiveBatch(!isActiveBatch, batchId)}
                                        toggled={isActiveBatch}
                                        labelA="No"
                                        // disabled={roleId!==1 && isSuperAdmin!=true}
                                        labelB="Yes"
                                    />
                                </div>
                            ),
                            header_2: (
                                <div>
                                    {batchDetails.map((batch) => {
                                        const { batchStartDate, batchEndDate } = batch;
                                        return (<div className="">
                                            <div className="text-capitalize">{batch.facultyName}</div>
                                            <div className="mb-2"><span className="xpa-text-size-sm">Start Date:{moment(batchStartDate).format('DD/MM/YYYY')}</span> - <span className="xpa-text-size-sm">End Date:{moment(batchEndDate).format('DD/MM/YYYY')}</span></div>
                                        </div>)
                                    })}
                                </div>
                            ),
                            header_3: (
                                // d-flex justify-content-center align-items-center
                                <div className="" >
                                    {roleId === 1 && isSuperAdmin === true &&
                                        <Button kind="primary" size="small" onClick={() => { this.handleEditBatch(batchId) }}>
                                            Edit Batch
                                        </Button>
                                    }
                                </div>
                            ),
                             header_4: (
                                <div className="" >
                                    {roleId === 1 && isSuperAdmin === true &&
                                        <Button kind="primary" size="small" onClick={() => { this.handleCloseJobBatch(batchId, batchName) }}>
                                            Close Jobs for Batch
                                        </Button>
                                    }
                                </div>
                            )
                        })
                    }) : []
            }
            selectable={false}
            // searchable
            // onSearchInputChange={handleSearchStudentsData}
            expandable={false}
            batchActions={false}
        />
          {/* {
                  (studentsCount && studentsCount > 0) ? (
                      <Pagination onChange={handleStudentsPaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={studentsActivePage} totalItems={studentsCount} />
                  ) : null
              } */}


                  {/* <div className="mt-3">
                    <h5>Current Batch - Reports</h5>

                    <div className="pb-2">
                      <h5>Interview Report</h5>
                      <a style={{ textDecoration: 'none' }} target="_blank" href={`/roboroy/api/v1/companyaddinfo/reports/${1}/interview?_=${new Date().getTime()}`}>
                        {t('download')} {t('report')}
                      </a>
                    </div>
                    <div className="pb-2">
                      <h5>Profiling Report</h5>
                      <a style={{ textDecoration: 'none' }} target="_blank" href={`/roboroy/api/v1/companyaddinfo/reports/${1}/profiling?_=${new Date().getTime()}`}>
                        {t('download')} {t('report')}
                      </a>
                    </div>

                  </div> */}


          <Modal
              open={isAddBatchModalOpen}
              onRequestClose={() => { this.handleAddBatchModal(false) }}
              onRequestSubmit={() => { this.handleAddBatchModalSubmit() }}
              primaryButtonText="Submit"
              secondaryButtonText="Cancel"
              modalHeading={modalHeading}
          >
              <div>
                  {false && showNameError === true && (
                      <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={
                              <span className="text-danger font-weight-bold">
                                  {/* {t('sameDateErrMsg')} */}
                                  {nameErrorMsg}
                              </span>
                          }
                          onCloseButtonClick={() =>
                              this.setState({ showNameError: false })
                          }
                      />
                  )}
                  <div>
                    <TextInput
                      placeholder={"Enter Batch Name"}
                      name="batchName"
                      labelText={<span>{"Batch Name"}{' '}<span className='text-danger'>*</span></span>}
                      value={batchNameValue}
                      disabled={modalHeading==="Edit Batch"}
                      onChange={(e) => { this.handleBatchNameChange(e) }}
                      invalid={showNameError}
                      invalidText={nameErrorMsg}
                    />
                  </div>
                  {/* {facultyFacets !== undefined && facultyFacets !== null && facultyKeys !== null && facultyKeys !== undefined &&
                  } */}
                  <div className="mt-2">
                    <div className="bx--label">
                      <span className="">{t('np_batch')}</span>{' '}
                      <span className="text-danger">{'*'}</span>
                    </div>
                    <DropDownBox
                      title=""
                      mainClassName="bx--form-item mb-0"
                      titleClass=""
                      labelClass=""
                      required
                      custom
                      className={`w-100 ${npBatchValInvalid ? 'invalid' : ''}`}
                      options={npBatchOptions || []}
                      placeholder={t('select_np_batch')}
                      onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                      name="npBatchVal"
                      selectedValue={npBatchVal || null}
                    />
                    {
                      npBatchValInvalid === true && (
                        <div style={{ fontSize: '.75rem', color: '#da1e28' }} className="mt-1">
                          {t('select_np_batch_err_msg')}
                        </div>
                      )
                    }
                  </div>
                  <div className="bx--label mt-3">Batch Details</div>
                  {this.state.showDateError === true && (
                      <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={
                              <span className="text-danger font-weight-bold">
                                  {/* {t('sameDateErrMsg')} */}
                                  {dateErrorMsg}
                              </span>
                          }
                          onCloseButtonClick={() =>
                              this.setState({ showDateError: false })
                          }
                      />
                  )}
                  <div className="text-capitalize">
                      {propsArr && propsArr !== null && propsArr !== undefined && Array.isArray(propsArr) && propsArr.length > 0 &&
                          propsArr.map((prop, indx) => (
                              <div className="d-flex align-items-center mb-1">
                                  <Checkbox
                                      checked={prop.isSelected}
                                      labelText={prop.label}
                                      onChange={(e) => this.handleSchoolSelect(indx)}
                                      id={`school_${indx}`}
                                  />

                                  {prop.isSelected &&
                                      <div className="d-flex align-items-center">
                                          {/* <div className="mr-2"> */}
                                          <DatePicker
                                              id={`start-date-picker_${indx}`}
                                              className="mr-2"
                                              onChange={(e) => this.onBatchDateChange(e, indx, "start")}
                                              datePickerType="single">
                                              <DatePickerInput
                                                  // className="mt-1"
                                                  id={`start-date-picker-input_${indx}`}
                                                  pattern="d{1,2}/d{4}"
                                                  placeholder="MM/DD/YYYY"
                                                  value={
                                                      prop.creatingBatch.startDate &&
                                                          prop.creatingBatch.startDate !== null &&
                                                          prop.creatingBatch.startDate !== ''
                                                          ? moment(prop.creatingBatch.startDate).format(
                                                              'MM/DD/YYYY'
                                                          ) : ''
                                                  }
                                              />
                                          </DatePicker>
                                          <DatePicker
                                              id={`end-date-picker_${indx}`}
                                              className="mr-2"
                                              onChange={(e) => this.onBatchDateChange(e, indx, "end")}
                                              datePickerType="single">
                                              <DatePickerInput
                                                  // className="mt-1"
                                                  id={`end-date-picker-input_${indx}`}
                                                  pattern="d{1,2}/d{4}"
                                                  placeholder="MM/DD/YYYY"
                                                  value={
                                                      prop.creatingBatch.endDate &&
                                                          prop.creatingBatch.endDate !== null &&
                                                          prop.creatingBatch.endDate !== ''
                                                          ? moment(prop.creatingBatch.endDate).format(
                                                              'MM/DD/YYYY'
                                                          ) : ''
                                                  }
                                              />
                                          </DatePicker>
                                      </div>
                                  }

                                  {prop.isSelected &&
                                      <div className="mr-2">
                                          <a
                                              href="#"
                                              onClick={() =>
                                                  this.handleClearDate(indx, prop.label)
                                              }>
                                              {t('clear')}
                                          </a>
                                      </div>
                                  }

                                  {/* </div> */}
                              </div>)
                          )
                      }
                  </div>
              </div>
          </Modal>

          {
            canCloseBatchJob == true ? (
            <Modal
              open={isCloseJobBatchModal}
              onRequestClose={() => { this.dismissCloseJobModal() }}
              onRequestSubmit={() => { this.handleJobBatchModalSubmit() }}
              primaryButtonText="Submit"
              secondaryButtonText="Cancel"
              modalHeading="Close Job"
            >
             <p className="bx--modal-content__text">
                <strong className="text-dark">
                  Are you sure you want to close jobs for this batch?
                      </strong>
                <small className="pt-1 text-dark d-block">
                  Remember that you should only do this if you are certain that you want to
                   close the batch, after all students have got their placement offers.
                </small>
              </p>
              <TextArea
                name="closeJobMessage"
                labelText="Please specify reason for closing this job."
                placeholder="Reason for closing this job"
                onChange={(ev) => { this.handleChange(ev); }}
              />
             </Modal>
            ) :(

              <Modal
                modalHeading="Close Job"
                passiveModal
                open={isCloseJobBatchModal}
                onRequestClose={() => { this.dismissCloseJobModal() }}
              >
                Currently, there are no open jobs under the selected batch.
              </Modal>
            )
          }

      </Fragment>
    );

  }
}

const mapStateToProps = state => ({
    user: state.x0paDuser.user,
    batchDetails: state.batchDetails.batchDetails,
    facultyData: state.candidateDetails.facultyData,
    facultyCount: state.candidateDetails.facultyCount,
})

const mapDispatchToProps = {
    getBatchDetails,
    getAllFaculties,
    setCurrentBatch,
    setActiveBatch,
    addNewBatch,
    editBatch,
    closeJobBatch,
    getJobsByBatch,
    getNpBatchList
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BatchManagementView));
