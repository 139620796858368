import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, InlineNotification } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import 'components/roboroy/CommonModal.css';

import parseResume from 'actions/talentSearch/resumeUpload';
import { reRenderContent } from 'actions/talentSearch/talentPool';
import { addProfileToJob } from 'actions/talentSearch/jobList';
import {
  addStudentProfileToJob,
} from 'actions/StudentProfileView';
import {
  findAllUserJobApplicants,
} from 'actions/talentSearch/candidatesList';

import './ResumeUpload.css';
import { toast } from 'react-toastify';

class ResumeUpload extends Component {
  state = {
    uploadedFile: '',
    uploadResume: false,
    showJobvacanicesErr: false,
    showLoader: false,
  };

  componentDidMount() {
    const { jobId } = this.props;
    if (!jobId) {
      this.setState({
        uploadResume: true,
      });
    }
  }

  dismiss = (e) => {
    if (e.target.className === 'xpa-modal--bg') {
      this.hide();
    }
  };

  hide = (flag) => {
    this.props.dismiss(flag);
    this.setState({
      showJobvacanicesErr: false,
    })
  };

  handleFileChange = (e) => {
    const { user: { userId, roleId } = {} } = this.props;
    const data = new FormData();
    const meta = {};
    if (roleId && Number(roleId) === 3) {
      meta.user_id = userId;
    } else {
      const {
        candidateProfile: {
          userProfile: {
            userId: candUserId,
          } = {},
        } = {},
      } = this.props;
      meta.user_id = candUserId;
    }
    if (this._jobId.value) {
      meta.job_id = this._jobId.value;
    }
    if (this._sharinghash && this._sharinghash.value !== '') {
      meta.sharinghash = this._sharinghash.value;
    }
    if (Object.keys(meta).length > 0) {
      data.append('meta', JSON.stringify(meta));
    }
    for (let i = 0; i < e.target.files.length; i++) {
      data.append('file', e.target.files[i]);
    }
    this.setState(
      {
        uploadedFile: e.target.value,
      },
      () => {
        this.uploadFile(data);
      },
    );
  };

  uploadFile = (data) => {
    const { isStudentRegPage, t } = this.props || {};
    window.data = data;
    // console.log("---- upload file data = ", data);
    this.hide(true);
    const uploadFileResult = this.props.parseResume(data, this.props.pageType, this.props.jobId);
    uploadFileResult.then((res) => {
      if(res && !res.error) {
        if (this.props.onUploadSuccess) {
          this.props.onUploadSuccess(res);
        }
        if(isStudentRegPage) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        const { error } = res || {};
        const { message } = error || {};
        toast.error(message || t('common_err_msg'));
        this.hide(false);
      }
    })
      .catch(err => console.error('Resume not uploaded', err));
    this.setState({
      uploadedFile: '',
    });
  };

  fetchNewData = () => {
    // let self = this;
    if (this.props.pageType === 'candidates') {
      this.props.findAllUserJobApplicants(this.props.jobId);
    } else if (this.props.pageType === 'talentpool') {
      this.props.reRenderContent();
    }
  };

  handleChangeRadioButton = (value) => {
    switch (value) {
      case 'no-upload':
        this.setState({
          uploadResume: false,
        });
        break;
      case 'upload':
        this.setState({
          uploadResume: true,
        });
        break;
      default:
        break;
    }
  }

  handleSubmitUploadResume = () => {
    const { jobId, user: { profileId } = {} } = this.props;
    this.setState({
      showLoader: true,
    }, () => {
      this.props.addStudentProfileToJob({ jobId, profileId }).then((res) => {
        if (res && !res.error) {
          this.setState({
            showLoader: false,
          }, () => {
            window.location.reload();
            this.hide();
          });
        } else if (res && res.error && res.error.code === 'x0pa-94') {
          this.setState({
            showJobvacanicesErr: true,
            showLoader: false,
          });
          setTimeout(() => {
            this.hide();
          }, 5000);
        }
      });
    });
  }

  render() {
    const { props } = this;
    const { multipleResume = true, t, pageType } = props;
    const { uploadResume } = this.state;
    const {
      dashboard, user: { userId, roleId } = {},
      jobId, finlandProfileId = null, country = '',
      heading = t('uploadResumes'),
    } = this.props;
    const { dashboard: { personDocId } = {} } = dashboard || {};
    return (
      <div>
        <Modal
          onRequestClose={() => this.hide(false)}
          open={props.active}
          passiveModal={(uploadResume === true || uploadResume === null)}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalLabel={t('action')}
          modalHeading={pageType === 'candidateApplication' ? 'Apply to Job' : 'Upload Resume'}
          onRequestSubmit={this.handleSubmitUploadResume}
        >
          {
            this.state.showJobvacanicesErr === true && (
              <InlineNotification
                lowContrast
                className="mt-0"
                title="This internship position is no longer available as it has been accepted by another student. Please apply for other positions / accept other offer(s) instead. Thank you."
                subtitle=""
                iconDescription=""
                kind="error"
              />
            )
          }
          {
            pageType === 'candidateApplication' && (
              <div>
                {t('applyjobMsg')}
                Do you want to confirm your job application?
              </div>
            )
          }
          {/* {
            roleId === 3 && (
              <div>
                {
                  jobId && (
                    <div>
                      <RadioButton
                        value="no-upload"
                        name="resume"
                        id="radio-1"
                        disabled={false}
                        onChange={(e) => { this.handleChangeRadioButton(e); }}
                        labelText={t('useExisting')}
                      />
                      {
                        uploadResume === false
                        && (
                          <div className="mt-1 ml-2 mb-2 font-weight-bold">
                            <Link
                              target="_blank"
                              to={`/view/${personDocId}/cv?_=${new Date().getTime()}&ref=${btoa(userId)}`}
                            >
                              View Resume
                            </Link>
                          </div>
                        )
                      }
                      <div className="mt-1 ml-2 mb-2 font-weight-bold">
                        <Link
                          target="_blank"
                          to={`/view/${personDocId}/cv?_=${new Date().getTime()}&ref=${btoa(userId)}`}
                        >
                          View Resume
                        </Link>
                      </div>
                      {(finlandProfileId === null || (country !== null && country !== '' && country.toLowerCase() !== 'finland')) && (
                        <RadioButton
                          value="upload"
                          name="resume"
                          id="radio-2"
                          disabled={false}
                          onChange={(e) => { this.handleChangeRadioButton(e); }}
                          labelText={t('uploadNewResume')}
                        />
                      )}
                    </div>
                  )
                }
              </div>
            )
          } */}
          {
            (uploadResume === true || roleId !== 3)
              && (
                <div className="bx--modal-content__text  pl-0">
                  <div className="xpa-modal__section">
                    <label htmlFor="jd_upload">
                      <div className="newjob_upload">
                        <span>{t('formats')}</span>
                      </div>
                      <div className="flexRow">
                        <input
                          type="hidden"
                          name="job_id"
                          id="job_id"
                          value={this.props.jobId}
                          ref={input => (this._jobId = input)}
                        />
                        <input
                          type="hidden"
                          name="sharinghash"
                          id="sharinghash"
                          value={this.props.sharinghash}
                          ref={input => (this._sharinghash = input)}
                        />                  
                        <input
                          type="file"
                          accept=".doc,.docx,.pdf,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          id="jd_upload"
                          name="uploadedFile"
                          value={this.state.uploadedFile}
                          onChange={this.handleFileChange}
                          multiple={multipleResume}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              )
          }
          {/* <Toast taskId={this.props.taskId} successAction={this.fetchNewData} /> */}
        </Modal>
        <JDProcessOverlay show={this.props.loading || this.state.showLoader} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.resumeUpload.loading,
  taskId: state.resumeUpload.task_id,
  user: state.x0paDuser.user,
  dashboard: state.candidateDashboard,
});
const mapActionsToProps = {
  parseResume,
  findAllUserJobApplicants,
  addProfileToJob,
  addStudentProfileToJob,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(ResumeUpload));
